var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-8 text-xs"},[_c('div',{staticClass:"rounded-2xl bg-white p-8"},[_c('div',{},[_vm._m(0),_c('div',{staticClass:"mt-4 grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-4"},[_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"w-full p-4 border border-gray-100 "},[_c('img',{staticClass:"w-full object-contain",attrs:{"src":_vm.data.foto ? ("" + _vm.default_url + (_vm.data.foto)) : require('@/assets/default.png')},on:{"click":function($event){return _vm.$refs.foto_profile.click()}}})])])]),_c('div',{staticClass:"col-span-8"},[_c('input',{ref:"foto_profile",staticStyle:{"display":"none"},attrs:{"type":"file","id":"foto_profile","accept":"image/*"},on:{"change":_vm.eventChange}}),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.updateProfile()}}},[_c('ValidationProvider',{staticClass:"grid grid-cols-12 items-center",attrs:{"rules":"required","name":"Password Lama","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("Nama")]),_c('div',{staticClass:"col-span-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name),expression:"data.name"}],ref:"name",class:("block border border-gray-200 w-72 p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"text","name":"name","id":"name","placeholder":"Nama"},domProps:{"value":(_vm.data.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "name", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs col-span-10"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"grid grid-cols-12 items-center mt-4",attrs:{"name":"Nama Perusahaan","vid":"company_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("Nama Perusahaan")]),_c('div',{staticClass:"col-span-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.company_name),expression:"data.company_name"}],ref:"company_name",class:("block border border-gray-200 w-72 p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"text","name":"company_name","id":"company_name","placeholder":"Nama Perusahaan"},domProps:{"value":(_vm.data.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "company_name", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"grid grid-cols-12 items-center mt-4",attrs:{"name":"Email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],ref:"email",class:"block border border-gray-200 w-72 p-2 px-4 rounded col-span-10 text-xs border-blue-300 bg-blue-50 placeholder-blue-200 text-blue-900 focus:border-blue-300",attrs:{"disabled":"","type":"text","name":"email","id":"email","placeholder":"Email"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "email", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"grid grid-cols-12 items-center mt-4",attrs:{"name":"Nomor Telepon","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("Nomor Telepon")]),_c('div',{staticClass:"col-span-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.phone),expression:"data.phone"}],ref:"phone",class:("block border border-gray-200 w-72 p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"text","name":"phone","id":"phone","placeholder":"Nomor Telepon"},domProps:{"value":(_vm.data.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "phone", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"grid grid-cols-12 items-center mt-4",attrs:{"name":"Alamat","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("Alamat")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.address),expression:"data.address"}],class:("block border border-gray-200 w-72 p-2 px-4 rounded text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"value":"Hello world","name":"my-textarea"},domProps:{"value":(_vm.data.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "address", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('button',{class:"text-center py-2 px-8 rounded\n                            text-white\n                            hover:bg-blue-900 bg-blue-500\n                            disabled:opacity-50\n                            mt-4\n                            \n                            focus:outline-none my-1",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v("Simpan")])],1)]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('span',{staticClass:"font-semibold text-base"},[_vm._v("Infromasi")])])}]

export { render, staticRenderFns }