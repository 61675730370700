<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">

            <div class="">
                      <div class="">
                            <span class="font-semibold text-base">Infromasi</span>
                        </div>
                    <div class="mt-4 grid grid-cols-12 gap-4">
                       <div class="col-span-4">
                           <div class="flex justify-center">
                               <div class="w-full p-4 border border-gray-100 ">
                                   <img :src="data.foto ? `${default_url}${data.foto}` : require('@/assets/default.png')" class="w-full object-contain" @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div>
                       <div class="col-span-8">
                            <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">

                            <ValidationObserver v-slot="{ invalid}" ref="form" >
                            <form @submit.prevent="updateProfile()" class="w-full" >
                            
                            <ValidationProvider rules="required" v-slot="{ errors }" name="Password Lama" vid="name" class="grid grid-cols-12 items-center">
                            <label for="" class="block mb-2 col-span-2">Nama</label>  
                            <div class="col-span-10">
                            <input
                                v-model="data.name"
                                type="text"
                                :class="`block border border-gray-200 w-72 p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="name"
                                ref="name"
                                id="name"
                                placeholder="Nama" />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            
                            </div>

                            </ValidationProvider>

                            <ValidationProvider v-slot="{ errors }" name="Nama Perusahaan" vid="company_name" class="grid grid-cols-12 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Nama Perusahaan</label>  
                            <div class="col-span-10">
                            
                            <input
                                :class="`block border border-gray-200 w-72 p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                
                                v-model="data.company_name"
                                type="text"
                                name="company_name"
                                ref="company_name"
                                id="company_name"
                                placeholder="Nama Perusahaan" />
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </div>
                            

                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" name="Email" vid="email" class="grid grid-cols-12 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Email</label>  
                            <input
                                disabled
                                v-model="data.email"
                                type="text"
                                :class="`block border border-gray-200 w-72 p-2 px-4 rounded col-span-10 text-xs border-blue-300 bg-blue-50 placeholder-blue-200 text-blue-900 focus:border-blue-300`"
                                name="email"
                                ref="email"
                                id="email"
                                placeholder="Email" />
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                            </ValidationProvider>

                            <ValidationProvider v-slot="{ errors }" name="Nomor Telepon" vid="phone" class="grid grid-cols-12 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Nomor Telepon</label>  
                            <div class="col-span-10">

                            <input
                                :class="`block border border-gray-200 w-72 p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"

                                v-model="data.phone"
                                type="text"
                                name="phone"
                                ref="phone"
                                id="phone"
                                placeholder="Nomor Telepon" />
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </div>

                            </ValidationProvider>


                            <ValidationProvider v-slot="{ errors }" name="Alamat" vid="address" class="grid grid-cols-12 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Alamat</label>  
                                                      
                            <textarea value="Hello world" v-model="data.address"
                                    :class="`block border border-gray-200 w-72 p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                
                                name="my-textarea" />
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                            </ValidationProvider>
                            

                            <button
                                :disabled="invalid"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                        </form>
                        </ValidationObserver>
                       </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import VSwatches from 'vue-swatches'

  // Import the styles too, globally
  import "vue-swatches/dist/vue-swatches.css"
export default {
    data: () => ({
        data: {
            name:'',
            username:'',
            email:'',
            company_name:'',
            address:'',
            color:'',
            phone:'',
            foto:''
        },
        foto:'',
        default_url:process.env.VUE_APP_IMAGES
    }),
    methods:{
        updateProfile(){
            let data = new FormData()
            data.append('name',this.data.name)
            data.append('company_name',this.data.company_name)
            data.append('phone',this.data.phone)
            data.append('address',this.data.address)
            data.append('color',this.data.color)
            if (this.foto) {
            data.append('foto',this.foto)
                
            }
            let headers = { headers:{
            'Authorization': 'Bearer ' + this.token,
            }}
            this.axios.post('v1/setting',data,headers)
            .then((ress) => {
               
                this.axios.get('v1/auth/me',headers)
                .then((ressponse) => {
                    console.log(ressponse)
                    this.setUser(ressponse.data)
                })
                 this.$snack.success({
                    text: ress.data.message,

                })
            })
            .catch((err) => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
        },
        getData() {
             let headers = { headers:{
            'Authorization': 'Bearer ' + this.token,
            }}
            this.axios.get('v1/setting',headers)
            .then((ress) => {
                this.data = ress.data.data
            })
             .catch((err) => {
                this.errorResponse(err)
            })
        },
         eventChange(event){
             this.default_url = ''
           const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load',()=>{
                this.data.foto=fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
       },
       ...mapActions({
           setUser:'auth/setUser'
       })
    },
    computed:{
        ...mapGetters({
            user:'auth/user',
            detail:'auth/detail',
            token:'auth/token',
        }),
       
    },
    async created() {
        await this.getData()
    },
    components:{
        ValidationProvider,ValidationObserver, VSwatches
    }
}
</script>